/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { Auth } from "aws-amplify";

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_vpK0u1yJF",
  aws_user_pools_web_client_id: "55vfd5kidetv6kucins5jfrbia",
  oauth: {
    domain: "admagic.auth.us-east-1.amazoncognito.com",
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["NAME"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_sign_up_verification_method: "link",
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  API: {
    endpoints: [
      {
        name: "user-data",
        endpoint:
          "https://569du9jl2c.execute-api.us-east-1.amazonaws.com/alpha",
        custom_header: async () => {
          // return { Authorization: 'token' };
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export default awsmobile;
