import { React } from "react";
import styles from "./InvalidPage.module.css";
import { Link } from "react-router-dom";
function InvalidPage() {
  return (
    <div className="container-center-horizontal">
      <div className={styles.invalidPageContainer}>
        <h1>Oops!</h1>
        <p> Something went wrong... 🙈</p>
        <Link to={{ pathname: "/" }}>
          <button href="">Back Home</button>
        </Link>
      </div>
    </div>
  );
}
export default InvalidPage;
