import "./App.css";
import React, { createContext } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Home";

import CodeAuth from "./components/CodeAuth";
import CreateAccount from "./components/CreateAccount";
import Dashboard from "./components/DashBoard";
import ShowAds from "./components/ShowAds";
import AdShare from "./components/AdShare";
import Payment from "./components/Payment";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./src/aws-exports";
import InvalidPage from "./components/InvalidPage";
const CreateContext = createContext(false);
Amplify.configure(awsconfig);
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/show-ad">
          <AdShare />
        </Route>
        <Route path="/ad">
          <ShowAds />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>

        <Route path="/createAccount">
          <CreateContext.Provider value={true}>
            <CreateAccount />
          </CreateContext.Provider>
        </Route>
        <Route path="/login">
          <CreateContext.Provider value={false}>
            <CreateAccount />
          </CreateContext.Provider>
        </Route>
        <Route path="/go">
          <CodeAuth />
        </Route>
        <Route path="/payment">
          <Payment />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="*" component={InvalidPage} />
      </Switch>
    </Router>
  );
}
export default App;
export { CreateContext };
