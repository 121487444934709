import React from "react";
import "./SecondSection.css";
import "../Home.css";
import Bullet from "./Bullet";

function SecondSection() {
  return (
    <div className="second-section">
      <div className="section-middle">
        <div className="section-middle-text">
          <p
            id="learn-your-audience"
            className="learn-your-audience-1 montserrat-bold-eternity-43px"
          >
            Learn your audience and attract newcomers.
          </p>
          <p
            id="our-comprehensive"
            className="our-comprehensive-da-1 montserrat-normal-black-20px"
          >
            Our comprehensive dashboard offers short-form advertising materials
            at a click away, generating content that tracks your online presence
            and grows alongside it. Complete with automated integrations for
            both your existing and new marketing accounts, extending your reach
            through social media could not be easier.
          </p>
        </div>
        <div className="section-middle-graphic">
          <div className="heart-bullets-1">
            <Bullet text="Generated on-demand video advertisements" />
            <Bullet text="Social media insights and analytics" />
            <Bullet text="Automated distribution to target audiences" />
            <Bullet text="Online presence monitoring" />
          </div>

          <img
            className="phone-graphic"
            src="/img/phone-screen.svg"
            alt="phone-screen-video"
          />
        </div>
      </div>
      <div className="stars-3">
        <div className="overlap-group4-7">
          <div className="line-container-8">
            <img
              className="asterisk_star"
              src="/img/asterisk_star.svg"
              alt="left asterisk star"
            />
          </div>
          <img className="star-22-3" src="/img/star-22-1.svg" alt="Star 22" />
        </div>

        <div className="overlap-group3-9">
          <img className="star-24-3" src="/img/star-24-1.svg" alt="Star 24" />
          <div className="line-container-9">
            <img
              className="asterisk_star"
              src="/img/asterisk_star_1.svg"
              alt="right asterisk star 2"
            />
          </div>
          <div className="line-container-10">
            <img
              className="asterisk_star"
              src="/img/asterisk_star_2.svg"
              alt="right asterisk star 1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
