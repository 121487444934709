import { React, useState, useEffect } from "react";
import "./AdShare.css";
import { API, Auth } from "aws-amplify";

function AdShare() {
  // get the video name from the url
  const urlParams = new URLSearchParams(window.location.search);
  const [videoName, setVideoName] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [isCopied, setIsCopied] = useState(false); // State to track copy status

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      API.get("user-data", "/get-user-data", {})
        .then((response) => {
          response = JSON.parse(response.body);
          setCity(user.attributes["custom:city"]);
          setName(user.attributes.name);
          const video_imt = `https://admagic-generated-videos.s3.amazonaws.com/${user.attributes.name.replace(
            " ",
            "_"
          )}-${user.attributes["custom:city"]}/`;
          setVideoName(`${video_imt}${urlParams.get("video")}`);
        })
        .catch((error) => {});
    });
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(videoName); // Copy videoName to clipboard
      setIsCopied(true); // Update copy status
      setTimeout(() => setIsCopied(false), 2000); // Reset copy status after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const downloadVideo = () => {
    const link = document.createElement("a");
    link.href = videoName;
    link.download = `${name}-${city}-${videoName.split("/").pop()}`; // Extract filename from URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container-center-horizontal share-ad">
      <div class="back-button">
        <a href="/dashboard"> &lt; Back </a>
      </div>
      <div class="horizontal-flex">
        <div class="video-flex">
          <label className="ad-video-container">
            <video className="video ad-video" controls src={videoName} />
          </label>
        </div>
        <div class="vertical-flex">
          <div class="sub-flex">
            <div class="header-text">{name}</div>
            <div class="subheading-text">{city}</div>
          </div>
          <div class="sub-flex">
            <button class="SAVE" onClick={downloadVideo}>
              {" "}
              SAVE{" "}
            </button>
            <button class="SHARE"> SHARE </button>
            <div class="link-horizontal-flex" onClick={handleCopy}>
              <div class="LINK"> {videoName} </div>
              {isCopied ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                </svg>
              ) : (
                <img
                  className="copy"
                  src="https://www.svgrepo.com/show/3110/copy.svg"
                  alt="copy"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdShare;
