import React, { useContext } from "react";
import LoginBlock from "./LoginBlock";
import styles from "./CreateAccount.module.css";
import { useLocation } from "react-router-dom";
import { CreateContext } from "../../App";
function CreateAccount() {
  return (
    <div className="container-center-horizontal">
      <img className={styles.vector2} src="/img/vector-2.svg" alt="Vector 2" />

      <div
        className={styles.createAccount}
        style={{
          display: "inline-flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoginBlock />
        <img
          className={styles.illustration}
          src="/img/illustration.svg"
          alt="Illustration"
        />
      </div>
    </div>
  );
}

export default CreateAccount;
