import { React, useState, useEffect } from "react";
import Star25 from "./Star25";
import "./CodeAuth.css";
import CodeNumber from "./CodeNumber";
import { Link, useHistory, useLocation, redirect } from "react-router-dom";
import axios from "axios";
import { Auth } from "aws-amplify";

function CodeAuth(props) {
  const { state } = useLocation();
  if (
    state === undefined ||
    state.city === undefined ||
    state.businessName === undefined
  ) {
    window.location.replace("/");
  }
  const { city, businessName } = state;
  const { createValidation } = props;
  const history = useHistory();
  // TODO: use useReducer instead of useState for code
  const [code0, setCode0] = useState("");
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");

  const [valid, setValid] = useState(false);

  const code = [code0, code1, code2, code3, code4, code5];
  const setCode = [setCode0, setCode1, setCode2, setCode3, setCode4, setCode5];

  const [validCode, setValidCode] = useState(true);
  // TODO: add error handling for axios funcs
  const handleEnter = (event) => {
    // TODO: add shake or feedback to user if code is invalid and they hit enter
    if (valid) {
      axios
        .post(
          `https://5ln275k4d2.execute-api.us-east-1.amazonaws.com/code-auth-check`,
          {
            businessName: businessName,
            city: city,
            access_code: code.join(""),
          }
        )
        .then((res) => {
          if (res.data.valid) {
            console.log("valid code");
            history.push({
              pathname: "/createAccount",
              state: {
                city: city,
                businessName: businessName,
              },
            });
          } else {
            setValidCode(false);
            console.log("invalid code");
          }
        })
        .catch((err) => {
          // console.log("error: ", err);
          setValidCode(false);
          // history.push({
          //   pathname: "/createAccount",
          // });
        });
    }
  };

  // function to set

  useEffect(() => {
    for (const code_ix of code) {
      if (code_ix === "") {
        setValid(false);
        return;
      }
    }
    setValid(true);
  }, [code]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        window.location.replace("/dashboard");
      })
      .catch((err) => {
        axios
          .get(
            `https://5ln275k4d2.execute-api.us-east-1.amazonaws.com/confirm-valid-business?businessName=${businessName}&city=${city}`
          )
          .then((res) => {
            if (res.data.signedUp) {
              window.location.replace("/login");
            }
            if (!res.data.valid) {
              console.log("invalid business");
              // TODO: redirect to error page
              window.location.replace("/");
            }
          });
      });
  }, []);

  const createCodeInputs = () => {
    let codeInputs = [];
    for (let i = 0; i < code.length; i++) {
      codeInputs.push(
        <CodeNumber
          key={i}
          id={i}
          value={code[i]}
          setValue={setCode[i]}
          handlePaste={handlePaste}
          handleEnter={handleEnter}
          validCode={validCode}
        />
      );
    }
    return codeInputs;
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text").replace(" ", "").replace("\n", "");
    // split into chars
    const paste_chars = paste.split("");
    if (paste.length === 6) {
      for (let i = 0; i < 6; i++) {
        setCode[i](paste[i]);
      }
      const next_element = document.getElementById("5");
      next_element.focus();
    }
  };
  // TODO: position stars properly on page
  return (
    <div className="container-center-horizontal">
      <div className="welcome screen">
        <div className="waves">
          <img className="vector-2" src="/img/vector-2.svg" alt="Vector 2" />
        </div>
        {/*<div className="overlap-group2">*/}
        {/*  <div className="left-stars">*/}
        {/*    <div className="star-container">*/}
        {/*      <Star25 />*/}
        {/*      <Star25 className="star-27" />*/}
        {/*    </div>*/}
        {/*    <img className="vector" src="/img/vector.svg" alt="Vector" />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="overlap-group1">
          <div className="flex-col">
            <div className="overlap-group3">
              <div className="content">
                <div className="frame-5">
                  <h1 className="title">Welcome to Admagic!</h1>
                  <p className="just-to-make-sure-yo">
                    Just to make sure you are the owner of {businessName},
                    please enter in the access code from your email in order to
                    access your dashboard:
                  </p>
                </div>
                <div className="frame-4">
                  <div className="access-code">{createCodeInputs()}</div>
                  <button
                    className="enter"
                    disabled={!valid}
                    onClick={handleEnter}
                  >
                    ENTER
                  </button>
                </div>
              </div>
              <div className="right-stars">
                <img
                  className="vector-1"
                  src="/img/vector-1.svg"
                  alt="Vector"
                />
                <div className="star-container-1">
                  <img
                    className="star-22"
                    src="/img/star-22.svg"
                    alt="Star 22"
                  />
                  <Star25 className="star-28" />
                </div>
                <img
                  className="vector-3"
                  src="/img/vector-3.svg"
                  alt="Vector"
                />
              </div>
            </div>
          </div>
          <p className="cant-find-access-co montserrat-normal-black-15px">
            <span className="montserrat-normal-black-15px">
              Can’t find access code in your email? No problem, we can send a
              new access code to you.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CodeAuth;
