import React, { useEffect, useState } from "react";
import "./FirstSection.css";
import "../Home.css";
import LeftStars from "./LeftStars";
import RightStars from "./RightStars";
import FormInput from "./FormInput";
import { Link } from "react-router-dom";

function FirstSection({ data, user }) {
  const [city, setCity] = useState("");
  const [businessName, setBusinessName] = useState("");

  const convertListToOptions = (list) => {
    const options = [];
    for (const item of list) {
      options.push({
        value: item,
      });
    }
    return options;
  };

  const [cityOptions, setCityOptions] = useState([]);
  const [businessNameOptions, setBusinessNameOptions] = useState([]);

  useEffect(() => {
    let cities = new Set();
    const businessNames = [];
    data.sort((a, b) => {
      if (a.business_name < b.business_name) {
        return -1;
      }
      if (a.business_name > b.business_name) {
        return 1;
      }
      return 0;
    });

    for (const item of data) {
      cities.add(item.city);
      businessNames.push(item.business_name);
    }
    // sort business names array alphabetically
    setCityOptions(convertListToOptions(cities));
    setBusinessNameOptions(convertListToOptions(businessNames));
  }, [data]);

  const [validCity, setValidCity] = useState(false);
  const [validBusiness, setValidBusiness] = useState(false);

  useEffect(() => {
    let found = false;
    for (option of cityOptions) {
      // check if validcity is the same as option.value but lowercase
      if (city.toLowerCase() === option.value.toLowerCase()) {
        setValidCity(true);
        found = true;
        break;
      }
    }
    if (!found) {
      setValidCity(false);
    }
  }, [city]);

  useEffect(() => {
    let found = false;
    for (option of businessNameOptions) {
      // check if validcity is the same as option.value but lowercase
      if (businessName.toLowerCase() === option.value.toLowerCase()) {
        setValidBusiness(true);
        found = true;
        break;
      }
    }
    if (!found) {
      setValidBusiness(false);
    }
  }, [businessName]);

  useEffect(() => {
    const newBusinessNameOptions = [];
    if (validCity) {
      for (const item of data) {
        if (item.city.toLowerCase() === city.toLowerCase()) {
          newBusinessNameOptions.push(item.business_name);
        }
      }
      setBusinessNameOptions(convertListToOptions(newBusinessNameOptions));
    } else {
      setBusinessName("");
    }
  }, [validCity]);

  return (
    <div className="first-section frame-1-1">
      <div className="grouped-content">
        <div className="stars">
          <LeftStars />
          <RightStars />
        </div>

        <div className="overlap-group7-2">
          <div
            id="ad-magic-logo"
            className="ad-magic-1 montserrat-bold-orchid-white-22px"
          >
            AdMagic&nbsp;
          </div>
          <img
            className="icon-label-1"
            src="/img/star-23-1.svg"
            alt="icon-label"
          />
        </div>
        <h1
          id="unlock-the-potential"
          className="montserrat-extra-bold-black-65px headline-text"
        >
          Unlock the potential of video marketing in an instant.
        </h1>
        <p
          id="automated-short-form"
          className="subheading-text montserrat-normal-heavy-metal-17px"
        >
          Automated short-form video advertising for your business starting at
          0$.
        </p>
        {!user && (
          <FormInput
            setCity={setCity}
            setBusinessName={setBusinessName}
            cityOptions={cityOptions}
            businessNameOptions={businessNameOptions}
            city={city}
            businessName={businessName}
            validCity={validCity}
            validBusiness={validBusiness}
          />
        )}
        {/*TODO: if logged in, just have go to dashboard button*/}
        <div class="go-button-div">
          {user ? (
            <Link
              to={{
                pathname: "/dashboard",
              }}
            >
              <button className="go-button montserrat-bold-white-22px">
                GO TO DASHBOARD
              </button>
            </Link>
          ) : (
            <Link
              to={{
                pathname: "/go",
                state: { city, businessName },
              }}
              disabled={!validCity || !validBusiness}
            >
              <button
                disabled={!validCity || !validBusiness}
                className="go-button montserrat-bold-white-22px"
              >
                GO
              </button>
            </Link>
          )}
        </div>

        <div className="login">
          <p className="login-text montserrat-normal-heavy-metal-14px">
            Access a suite of digital marketing solutions, no input required.
          </p>
          {/*<img className="login-pic" src="/img/login@2x.png" alt="LOGIN" />*/}
        </div>
        <a className="early-access-text montserrat-normal-heavy-metal-18px" href="https://mailchi.mp/3d89ef6f851d/admagic-early-access" target="_blank">
            Sign up for early access here!
        </a>
      </div>

      {divider}
    </div>
  );
}

const divider = (
  <div className="custom-shape-divider-bottom-1675442855">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path
        d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
        opacity=".25"
        className="shape-fill"
      ></path>
      <path
        d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
        opacity=".5"
        className="shape-fill"
      ></path>
      <path
        d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
        className="shape-fill"
      ></path>
    </svg>
  </div>
);

export default FirstSection;
