import React from "react";

import "./Footer.css";

function Footer() {
  return (
    <div className="section-bottom-1">
      <div className="bottom-categories">
        <div className="bottom-category">
          <h1 className="footer-titles montserrat-bold-cream-14px">FAQ</h1>
          <a className="montserrat-normal-cream-13px">Process</a>
        </div>
        <div className="bottom-category">
          <h1 className="footer-titles montserrat-bold-cream-14px">CAREERS</h1>
          <a className="montserrat-normal-cream-13px">Careers</a>
        </div>
        <div className="bottom-category">
          <h1 className="footer-titles montserrat-bold-cream-14px">ABOUT</h1>
          <a className="montserrat-normal-cream-13px">Team</a>
          <a className="montserrat-normal-cream-13px">Background</a>
        </div>
        <div className="bottom-category">
          <h1 className="footer-titles montserrat-bold-cream-14px">CONTACT</h1>
          <a
            href="mailto:admagic.co@gmail.com"
            className="montserrat-normal-cream-13px"
          >
            {" "}
            admagic.co@gmail.com{" "}
          </a>
        </div>
      </div>
      <div className="copyright montserrat-normal-cream-14px">
        <p>© Made in 2023</p>
      </div>
    </div>
  );
}

export default Footer;
