import { React, useEffect, useState } from "react";
import "./LoginBlock.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { CreateContext } from "../../../App";
import { Auth } from "aws-amplify";
import isEmail from "validator/lib/isEmail";
import { useLocation } from "react-router-dom";
import { Hub } from "aws-amplify";
import axios from "axios";

function LoginBlock() {
  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dualPassword, setDualPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [focusPassword, setFocusPassword] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [resentEmail, setResentEmail] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const create = useContext(CreateContext);
  const { state } = useLocation();
  // TODO: add error message if email is in use

  async function signUp() {
    try {
      const { city, businessName } = state;
      console.log("signing up");
      console.log(email, password, businessName, city, keepLoggedIn);
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
          name: businessName, // optional
          "custom:city": city, // optional - E.164 number convention
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
    } catch (error) {
      console.log("error signing up:", error);
    }
  }

  async function signIn() {
    console.log("signing in");
    setInvalidLogin(false);
    try {
      console.log(email, password);
      const user = await Auth.signIn(email, password).then((user) => {
        console.log(Auth.currentAuthenticatedUser());
      });
      console.log(user);
    } catch (error) {
      console.log("error signing in", error);
      setInvalidLogin(true);
    }
  }
  function verifyPassword(password) {
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasNumber = /\d/;
    let message = [];
    let valid = true;
    if (password.length < 8) {
      message.push("at least 8 characters");
      valid = false;
    }
    if (!hasUppercase.test(password)) {
      message.push("at least 1 uppercase letter");
      valid = false;
    }
    if (!hasLowercase.test(password)) {
      message.push("at least 1 lowercase letter");
      valid = false;
    }
    if (!hasNumber.test(password)) {
      message.push("at least 1 number");
      valid = false;
    }

    return { valid, message };
  }

  useEffect(() => {
    const { valid, message } = verifyPassword(password);
    if (valid) {
      setValidPassword(true);
      setPasswordMessage("");
    } else {
      setValidPassword(false);
      setPasswordMessage(`Requires: ${message.join(", ")}`);
    }
  }, [password]);

  function tryLogin() {
    console.log("trying to login");
    if (create) {
      setVerifyEmail(true);
      signUp();
    } else {
      signIn();
    }
  }

  function togglePasswordMessage(value) {
    setFocusPassword(value);
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        window.location.replace("/dashboard");
      })
      .catch((err) => {
        if (create) {
          if (
            state === undefined ||
            state.city === undefined ||
            state.businessName === undefined
          ) {
            window.location.replace("/");
          }
        }
        console.log(err);
      });

    const listener = ({ payload }) => {
      const { event } = payload;
      console.log(`event: ${event}`);
      if (event === "autoSignIn") {
        const user = payload.data;
        console.log(`user ${user.email} has been signed in automatically`);
        console.log(user);
        // redirect to dashboard
        window.location.replace("/dashboard");
      } else if (event === "autoSignIn_failure") {
        // redirect to sign in page
        console.log("auto sign in failed");
        window.location.replace("/login");
      } else if (event === "signIn") {
        // redirect to dashboard
        console.log("sign in success");
        window.location.replace("/dashboard");
      }
    };

    Hub.listen("auth", listener);

    // Clean up the listener when the component unmounts
    return () => {
      Hub.remove("auth", listener);
    };
  }, []);

  async function resendEmail() {
    try {
      await Auth.resendSignUp(email);
      setResentEmail(true);
    } catch (error) {
      console.log("error resending email:", error);
    }
  }

  async function deleteUser() {
    try {
      // const result = await Auth.deleteUser();
      // TODO: delete user from database if unconfirmed
      setVerifyEmail(false);
    } catch (error) {
      console.log("Error deleting user", error);
    }
  }

  return (
    <div className="frame-20">
      {!verifyEmail ? (
        <>
          <h1 className="welcome-to-ad-magic">
            <span className="span0">Welcome {!create && "back"} to </span>
            <span className="AdMagic">AdMagic!</span>
          </h1>
          <div className="frame-48">
            <InputBox
              img="img/vector-4.svg"
              text="Email"
              fieldChange={setEmail}
              validator={isEmail}
              value={email}
              invalid={invalidLogin}
            />
            <PasswordBox
              img="img/key@2x.png"
              text="Password"
              hidden={hidden}
              setHidden={setHidden}
              fieldChange={setPassword}
              togglePasswordMessage={togglePasswordMessage}
              validator={(password) => verifyPassword(password).valid}
              value={password}
              invalid={invalidLogin}
            />
            {!create && invalidLogin && (
              <div
                className={
                  focusPassword ? "password-message" : "password-message-alert"
                }
              >
                Your login information is incorrect - please try again.
              </div>
            )}
            {create && (
              <>
                {!validPassword && password.length > 0 && (
                  <div
                    className={
                      focusPassword
                        ? "password-message"
                        : "password-message-alert"
                    }
                  >
                    {passwordMessage}
                  </div>
                )}
                {validPassword && (
                  <InputBox
                    img={
                      password === dualPassword && password.length !== 0
                        ? "img/key@2x.png"
                        : "img/key-1@2x.png"
                    }
                    text="Re-enter password"
                    hidden={hidden}
                    fieldChange={setDualPassword}
                    validator={(dualPassword) => dualPassword === password}
                    value={dualPassword}
                  />
                )}
              </>
            )}
            <div className="left-keep-signin">
              <label className="keep-me-signed-in">
                <input
                  type="checkbox"
                  class="check"
                  onClick={() => setKeepLoggedIn(!keepLoggedIn)}
                />
                <span className="keep-me-signed-in-1">Keep me signed in</span>
              </label>
            </div>
            <button
              className="create-account-button"
              disabled={
                !(
                  validPassword &&
                  isEmail(email) &&
                  password === dualPassword
                ) && create
              }
              onClick={tryLogin}
            >
              {" "}
              {create ? "CREATE ACCOUNT" : "LOG IN"}
            </button>
          </div>{" "}
        </>
      ) : (
        <div className="email-verification">
          <header id="verify-your-email">Verify your email</header>
          <p id="verify-your-email-text">
            We've sent an email to <b>{email}</b> - please click the link to
            verify your email address and gain access to your dashboard.
          </p>
          <button id="resend-email" onClick={resendEmail}>
            RESEND EMAIL
          </button>
          {resentEmail && (
            <p id="resend-email-text">
              We've resent an email, please check your spam!
            </p>
          )}
          <button id="use-different-email" onClick={deleteUser}>
            USE A DIFFERENT EMAIL
          </button>
        </div>
      )}
    </div>
  );
}

function PasswordBox(props) {
  const {
    img,
    text,
    hidden,
    setHidden,
    fieldChange,
    togglePasswordMessage,
    validator,
    value,
    invalid,
  } = props;
  const toggleShow = () => {
    setHidden(!hidden);
  };

  return (
    <label
      className={
        (value.length !== 0 && !validator(value)) || invalid
          ? "signup-error"
          : "signup"
      }
    >
      <img class="signup-input-icon" src={img} alt="img" />
      <input
        class="signup-text"
        id={text}
        placeholder={text}
        type={hidden !== undefined && hidden ? "password" : "text"}
        onChange={(e) => fieldChange(e.target.value)}
        onFocus={() => togglePasswordMessage(true)}
        onBlur={() => togglePasswordMessage(false)}
      />
      {setHidden !== undefined && (
        <img
          class="signup-input-icon"
          src={hidden ? "img/closed-eye.svg" : "img/bi-eye-fill.svg"}
          alt="img"
          onClick={toggleShow}
        />
      )}
    </label>
  );
}

function InputBox(props) {
  const { img, text, hidden, fieldChange, validator, value, invalid } = props;

  return (
    <label
      className={
        (value.length !== 0 && !validator(value)) || invalid
          ? "signup-error"
          : "signup"
      }
    >
      <img class="signup-input-icon" src={img} alt="img" />
      <input
        class="signup-text"
        id={text}
        placeholder={text}
        type={hidden !== undefined && hidden ? "password" : "text"}
        onChange={(e) => fieldChange(e.target.value)}
      />
    </label>
  );
}

export default LoginBlock;
