import React from "react";
import "./SecondSection.css";
function Bullet(props) {
  const { text } = props;

  return (
    <div className={`heart-1-1`}>
      <img className="icon-star" src="/img/star-25.svg" alt="icon-star" />
      <div className="bullet-text sourcesans3-normal-black-marlin-20px">
        {text}
      </div>
    </div>
  );
}

export default Bullet;
