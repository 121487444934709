import React from "react";
import "./Star25.css";

function Star25(props) {
  const { className } = props;

  return (
    <article className={`star-26 ${className || ""}`}>
      <img className="icon-star" src="/img/star-25.svg" alt="icon-star" />
    </article>
  );
}

export default Star25;
