.createAccount {
  align-items: center;
  border: 1px none;
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
  position: relative;
  gap: 7%;
}

.vector2 {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 183px;
  z-index: 1;
}

.illustration {
  height: 28em;
  margin-bottom: 206.24px;
  margin-top: 100px;
}

/*the 1012 number is super scuffed! didn't write resizing js function so its from trial and error*/
@media screen and (max-width: 1012px) {
  .illustration {
    display: none;
  }
}

.account-overlap-group {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(255, 246, 227) 0%,
    rgb(255, 249.58, 239.06) 100%
  );
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  gap: 55px;
  min-width: 1703px;
}
