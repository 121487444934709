import React, { useState, useEffect } from "react";
import "./Payment.css";

function Payment() {
  const [paymentLink, setPaymentLink] = useState("");

  useEffect(() => {
    setPaymentLink("https://buy.stripe.com/00g8wS6sDeph9FKdQR");
  }, []);

  return (
    <div className="container-center-horizontal payment-container">
      <iframe
        src={paymentLink}
        frameBorder="0"
        scrolling="no"
        height="500"
        width="100%"
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  );
}

export default Payment;
