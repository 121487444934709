import { React, useEffect, useState, createRef } from "react";
import "./CodeNumber.css";

import { useHistory } from "react-router-dom";

function CodeAuth(props) {
  let { value, setValue, id, handlePaste, handleEnter, validCode } = props;
  const history = useHistory();
  const handleKeyDown = (event) => {
    const isAlphanumeric = (key) => /^[0-9a-zA-Z]$/.test(key);
    if (event.key === "v" && (event.ctrlKey || event.metaKey)) {
      return true;
    }
    else if (
      (isAlphanumeric(event.key) || event.key === "ArrowRight") &&
      document.activeElement.id === id.toString()
    ) {
      if (event.key !== "ArrowRight") {
        setValue(event.key.toUpperCase());
      }
      const next_id = Math.min(parseInt(document.activeElement.id) + 1, 5);
      const next_element = document.getElementById(next_id.toString());
      if (next_element) {
        next_element.focus();
      }
    } else if (
      (event.key === "Backspace" || event.key == "ArrowLeft") &&
      document.activeElement.id === id.toString()
    ) {
      // move to the previous input
      if (event.key !== "ArrowLeft") {
        setValue("");
      }
      const prev_id = Math.max(parseInt(document.activeElement.id) - 1, 0);
      const prev_element = document.getElementById(prev_id.toString());
      if (prev_element) {
        prev_element.focus();
      }
    } else if (event.key === "Enter") {
      console.log(validCode);
      handleEnter();
    }

    return false;
  };

  return (
    <input
      id={props.id}
      value={value}
      className={validCode ? "code-number" : "code-number invalid"}
      maxLength={1}
      pattern="[0-9]"
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      autoComplete="off"
      contentEditable="true"
      onChange={() => {}}
    />
  );
}

export default CodeAuth;
