import React from "react";
import "../FirstSection.css";
import "../../Home.css";

function LeftStars() {
  return (
    <div className="stars-left">
      <div className="overlap-group2-8">
        <div className="line-container-6">
          <img className="line-6-4" src="/img/line-6-1.svg" alt="Line 6" />
          <img className="line-1-6" src="/img/line-1-1.svg" alt="Line 1" />
          <img className="line-2-6" src="/img/line-2-1.svg" alt="Line 2" />
          <img className="line-3-1" src="/img/line-3-1.svg" alt="Line 3" />
          <img className="line-5-5" src="/img/line-5-1.svg" alt="Line 5" />
          <img className="line-4-1" src="/img/line-4-1.svg" alt="Line 4" />
        </div>
        <img className="star-22-2" src="/img/star-22.svg" alt="Star 22" />
      </div>
      <img className="star-23-1" src="/img/star-23.svg" alt="Star 23" />
    </div>
  );
}

export default LeftStars;
