import {React, useEffect, useRef, useState} from "react";
import "./Dashboard.css";
import {useHistory} from "react-router-dom";
import {API, Auth} from "aws-amplify";

function Dashboard() {
    const [generating, setGenerating] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentGeneratedVids, setCurrentGeneratedVids] = useState([]);
    const [user, setUser] = useState(null);
    const [generatedVids, setGeneratedVids] = useState([]);
    const [contentUser, setContentUser] = useState([]);
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [analysis, setAnalysis] = useState(false);
    const [analysisData, setAnalysisData] = useState([]);
    const [generatedAdsLink, setGeneratedAdsLink] = useState("");
    const [userContentLink, setUserContentLink] = useState("");
    const [distribution, setDistribution] = useState(true);
    const history = useHistory();
    const colors = [
        "#F7E0FF",
        "#FFE6E1",
        "#FFEBD5",
        "#E1E6FF",
        "#FFE1F1",
        "#FFE6E1",
    ];

    console.log(analysisData);
    // join username with _

    const toggleGenerating = () => {
        API.post("user-data", "/generate-video", {
            body: {},
        })
            .then((response) => {
            })
            .catch((error) => {
            });
        setGenerating(!generating);
    };

    useEffect(() => {
        setGeneratedAdsLink(
            `https://admagic-generated-videos.s3.amazonaws.com/${name.replace(
                " ",
                "_"
            )}-${city}/`
        );
        setUserContentLink(
            `https://admagic-user-content.s3.amazonaws.com/${name.replace(
                " ",
                "_"
            )}-${city}/`
        );
    }, [name, city]);

    // start progress increments when generating is true
    useEffect(() => {
        let intervalId;
        if (generating && progress < 100) {
            intervalId = setInterval(() => {
                setProgress((progress) => progress + 1);
            }, 1000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [generating]);

    const fetchData = (retryCount = 0) => {
        API.get("user-data", "/get-user-data", {})
            .then((response) => {
                response = JSON.parse(response.body);
                // If current_generated either does not exist or is an empty array
                if (
                    !response.current_generated ||
                    response.current_generated.length === 0
                ) {
                    // Retry up to 2 times with 3-second intervals
                    if (retryCount < 2) {
                        setTimeout(() => fetchData(retryCount + 1), 3000);
                    } else {
                        console.error(
                            "Unable to retrieve current_generated after multiple attempts"
                        );
                    }
                } else {
                    // Add your code here
                    history.push("/ad", {
                        currentGeneratedData: response.current_generated.map(
                            (s) => generatedAdsLink + s
                        ),
                    });
                }
            })
            .catch((error) => {
            });
    };

    useEffect(() => {
        if (progress >= 23) {
            setGenerating(false);
            fetchData();
        }
    }, [progress]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                setName(user.attributes.name);
                setCity(user.attributes["custom:city"]);
                API.get("user-data", "/get-user-data", {})
                    .then((response) => {
                        response = JSON.parse(response.body);
                        setGeneratedVids(response.generated_ads.reverse());
                        setCurrentGeneratedVids(response.current_generated);
                        setContentUser(response.content);
                        setAnalysisData(response.tags);
                        console.log(response);
                        // Add your code here
                    })
                    .catch((error) => {
                    });
            })
            .catch((err) => {
                history.push("/login");
            });
    }, []);

    // Create an array of video refs
    const videoRefs = useRef([]);

    const handleVideoClick = (index) => {
        if (videoRefs.current[index]) {
            videoRefs.current[index].pause();
        }
        const file = generatedVids[index];
        history.push({
            pathname: "/show-ad",
            search: `?video=${file}`,
        });
    };

    const createVideos = (videos, link, share) => {
        let videoPreviews = [];
        for (let index = 0; index < videos.length; index++) {
            const file = videos[index];
            const video_imt = `${link}${encodeURIComponent(file)}`;
            videoPreviews.push(
                <label className="ad-video" style={{position: "relative"}}>
                    <video
                        ref={(el) => (videoRefs.current[index] = el)}
                        id={index}
                        className="video ad-video"
                        height="375em"
                        width="225em"
                        controls
                        src={video_imt}
                        type="video/mp4"
                    >
                        <source/>
                    </video>
                    {share && (
                        <div
                            className="share-icon"
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                cursor: "pointer",
                                zIndex: 1,
                            }}
                            onClick={() => handleVideoClick(index)}
                        >
                            {/* Replace the content below with your actual SVG code */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="white"
                                    d="M18,14a4,4,0,0,0-3.08,1.48l-5.1-2.35a3.64,3.64,0,0,0,0-2.26l5.1-2.35A4,4,0,1,0,14,6a4.17,4.17,0,0,0,.07.71L8.79,9.14a4,4,0,1,0,0,5.72l5.28,2.43A4.17,4.17,0,0,0,14,18a4,4,0,1,0,4-4ZM18,4a2,2,0,1,1-2,2A2,2,0,0,1,18,4ZM6,14a2,2,0,1,1,2-2A2,2,0,0,1,6,14Zm12,6a2,2,0,1,1,2-2A2,2,0,0,1,18,20Z"
                                ></path>
                            </svg>
                        </div>
                    )}
                </label>
            );
        }
        return videoPreviews;
    };

    const createTags = (tags, tagColor) => {
        let tagPreviews = [];
        for (let tag of tags) {
            tagPreviews.push(
                <div className="analytics-tag" style={{background: tagColor}}>
                    {tag}
                </div>
            );
        }
        return tagPreviews;
    };
    const createTagCategory = (tags) => {
        let color = 0;
        let tagCategories = [];
        for (let tagCategory in tags) {
            const tagsInCategory = tags[tagCategory];
            const tagColor = colors[color % colors.length];
            tagCategories.push(
                <div className="analytics-group">
                    <label className="analytics-category">{tagCategory}:</label>
                    {createTags(tagsInCategory, tagColor)}
                </div>
            );
            color += 1;
        }
        return tagCategories;
    };
    return (
        //   TODO: convert flexbox to grid
        <div className="container-center-horizontal dashboard">
            {generating && (
                <div className="loading-popup">
                    <div className="loading-popup-box">
                        <div class="loading-text">Generating your ad...</div>
                        <div class="loading-messages">
                            <div class="loading-text-gradient">
                                <div className={"loading-message"}>
                                    {progress >= 1 && "Locating your relevant content...📍"}
                                </div>
                                <div className="loading-message">
                                    {progress >= 5 && "Filtering and upscaling material... 📽"}
                                </div>
                                <div className="loading-message">
                                    {progress >= 8 && "Piecing together visuals... 🎞"}
                                </div>
                                <div className="loading-message">
                                    {progress >= 15 && "Writing custom labels... 💬"}
                                </div>
                                <div className="loading-message">
                                    {progress >= 20 && "Syncing with audio... 🎵"}
                                </div>
                            </div>
                            <div className="progress-bar-container">
                                <div className="progress-bar-child progress"></div>
                                <div
                                    className="progress-bar-child shrinker timelapse"
                                    style={{animationDuration: "24s"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div class="horizontal-flex" style={{padding: "20px 30px"}}>
                <div class="dashboard-text">Dashboard 🪄</div>
                <div class="dashboard-user-icons">
                    <UserButton
                        icon={<img src={"img/user.svg"} alt="User" height="20em"/>}
                        text={name}
                    />
                    <UserButton
                        icon={<img src={"img/settings.png"} alt="Settings" height="20em"/>}
                        text="Settings"
                    />
                </div>
            </div>

            <div className="horizontal-flex" style={{padding: "10px 30px"}}>
                <div className="vertical-flex" style={{flex: 1}}>
                    <div className="dashboard-box">
                        <div className="padded-dashboard-box">
                            {/*<UserButton*/}
                            {/*  type={*/}
                            {/*    <span style={{ fontSize: "10px" }}>*/}
                            {/*      <b style={{ fontWeight: 600 }}>1</b> free ad remaining*/}
                            {/*    </span>*/}
                            {/*  }*/}
                            {/*/>*/}
                            {/*<AdButton*/}
                            {/*  text={*/}
                            {/*    currentGeneratedVids.length === 0*/}
                            {/*      ? "GENERATE 1 AD"*/}
                            {/*      : "VIEW GENERATED ADS"*/}
                            {/*  }*/}
                            {/*  background="linear-gradient(84.27deg, #E1A9F5 1.34%, #F17256 35.78%, #F5BA20 70.69%, #FFD600 88.62%)"*/}
                            {/*  fontSize="15px"*/}
                            {/*  // discount={*/}
                            {/*  //   <span>*/}
                            {/*  //     $5 <s style={{ fontSize: "16px", color: "black" }}>10</s>*/}
                            {/*  //   </span>*/}
                            {/*  // }*/}
                            {/*  onClick={*/}
                            {/*    currentGeneratedVids.length === 0*/}
                            {/*      ? toggleGenerating*/}
                            {/*      : () =>*/}
                            {/*          history.push("/ad", {*/}
                            {/*            currentGeneratedData: currentGeneratedVids.map(*/}
                            {/*              (s) => generatedAdsLink + s*/}
                            {/*            ),*/}
                            {/*          })*/}
                            {/*  }*/}
                            {/*/>*/}
                            {/*<AdButton*/}
                            {/*  text="GENERATE 3 ADS"*/}
                            {/*  background="linear-gradient(228.52deg, #E1A9F5 -30.47%, #F17256 55.75%, #F5BA20 143.15%, #FFD600 188.04%)"*/}
                            {/*  discount={*/}
                            {/*    <span>*/}
                            {/*      $12 <s style={{ fontSize: "16px", color: "black" }}>15</s>*/}
                            {/*    </span>*/}
                            {/*  }*/}
                            {/*  onClick={toggleGenerating}*/}
                            {/*/>*/}
                            <AdButton
                                text="SUBSCRIBE"
                                background="linear-gradient(84.27deg, #E1A9F5 1.34%, #F17256 35.78%, #F5BA20 70.69%, #FFD600 88.62%)"
                                fontSize="15px"
                                discount={
                                    <span>
                    $10 <s style={{fontSize: "16px", color: "black"}}>20</s>/mo.
            </span>
                            }
                                onClick={() => window.open("https://buy.stripe.com/00g3cy9EP5SL6tybIL", "_blank")}

                            />
                            <AdButton
                                text="SUBSCRIBE"
                                background="linear-gradient(196.92deg, #383432 17.74%, #FA00FF 329.8%)"
                                fontSize="15px"
                                discount={
                                    <span>
                    $100 <s style={{fontSize: "16px", color: "black"}}>200</s>/yr.
            </span>
                                }
                                onClick={() => window.open("https://buy.stripe.com/eVafZkdV56WP19e3cg", "_blank")}
                            />
                        </div>
                    </div>
                    <div className="dashboard-box" style={{width: "100%"}}>
                        <div className="padded-dashboard-box" style={{width: "100%"}}>
                            <div className="dashboard-company-info">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            fontSize: "20px",
                                            textAlign: "left",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        {name}
                                    </div>
                                    <div
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "17px",
                                            textAlign: "right",
                                            color: "#F36F56",
                                            marginBottom: "3px",
                                        }}
                                    >
                                        {city}
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-company-info-lines">
                                <img src="img/picture.svg" height="30em" alt="Picture Icon"/>
                                <span style={{fontSize: "13px"}}>
                  <b style={{fontWeight: 700}}>{contentUser.length}</b> videos found across
                  social media
                </span>
                            </div>
                            <div className="dashboard-company-info-lines">
                                <img src="img/camera.svg" height="30em" alt="Camera Icon"/>
                                <span style={{fontStyle: "italic", fontSize: "13px"}}>
                  photo content - coming soon
                </span>
                            </div>
                {/*            <div*/}
                {/*                className="dashboard-company-info-lines"*/}
                {/*                style={{paddingBottom: "15px"}}*/}
                {/*            >*/}
                {/*                <img src="img/house.svg" height="30em" alt="Camera Icon"/>*/}
                {/*                <span style={{fontSize: "13px"}}>*/}
                {/*  <b style={{fontWeight: 700, fontSize: "13px"}}>*/}
                {/*    Rating: 4.3*/}
                {/*  </b>{" "}*/}
                {/*                    (via <b style={{fontWeight: 700, fontSize: "13px"}}>130</b>{" "}*/}
                {/*                    Google Maps reviews)*/}
                {/*</span>*/}
                {/*            </div>*/}
                            <UserButton
                                padding="7px 10px"
                                radius="18px"
                                text={
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            color: "#F36F56",
                                        }}
                                    >
                    Edit Profile
                  </span>
                                }
                            />
                        </div>
                    </div>
                    <FeedbackForm analysis={true}/>
                </div>
                <div
                    className="vertical-flex"
                    style={{flex: 3, minWidth: "240px"}}
                >
                    <div className="dashboard-box" style={{width: "100%"}}>
                        <div class="your-content-text">Your Ads 📷</div>
                        <hr style={{border: "1px solid #bbb", width: "100%"}}/>
                        <div className="dashboard-content-scroll">
                            {generatedVids.length === 0 ? (
                                <div className="content-placeholder">
                                    <div className="content-placeholder-text">
                                        No ads yet - try generating one on the left!
                                    </div>
                                </div>
                            ) : (
                                createVideos(generatedVids, generatedAdsLink, true)
                            )}
                        </div>
                        <div className="dashboard-content-share">
                        </div>
                        {/*<div className="dashboard-content-share">*/}
                        {/*  <AdButton*/}
                        {/*    text="SHARE"*/}
                        {/*    background="linear-gradient(224.83deg, #E1A9F5 -83.1%, #D093E6 16.18%, #F36F56 138.18%)"*/}
                        {/*    fontSize="15px"*/}
                        {/*  />*/}
                        {/*  <AdButton*/}
                        {/*    text="SAVE"*/}
                        {/*    background="linear-gradient(180deg, #3A2B19 0%, #3A2B19 100%)"*/}
                        {/*    fontSize="15px"*/}
                        {/*  />*/}
                        {/*  <AdButton*/}
                        {/*    text="DELETE"*/}
                        {/*    background="linear-gradient(180deg, #E0795C 0%, #D86443 100%)"*/}
                        {/*    fontSize="15px"*/}
                        {/*  />*/}
                        {/*</div>*/}
                    </div>
                    {analysis && (
                        <div className="dashboard-box" style={{width: "100%"}}>
                            <div className="your-content-text">
                                Recent Content About You 👥
                            </div>
                            <hr style={{border: "0.5px solid #bbb", width: "100%"}}/>
                            <div className="dashboard-content-scroll">
                                {contentUser.length === 0 ? (
                                    <div className="content-placeholder">
                                        <div className="content-placeholder-text">
                                            No content found yet - give us a few minutes to analyze!
                                        </div>
                                    </div>
                                ) : (
                                    createVideos(contentUser, userContentLink, false)
                                )}
                            </div>
                            <div className="dashboard-content-share"></div>
                            <hr style={{border: "0.5px solid #bbb", width: "100%"}}/>
                            <div className="your-content-text">Recent Insights 🌱</div>
                            <div
                                className="analytics"
                                style={{padding: "15px 40px 40px 40px"}}
                            >
                                {createTagCategory(analysisData)}
                            </div>
                        </div>
                    )
                    }
                    {distribution && (
                        <div className="dashboard-box" style={{width: "100%"}}>
                            <div className="your-content-text">
                                Content Distribution 📊
                            </div>
                            <hr style={{border: "0.5px solid #bbb", width: "100%"}}/>

                            <div className="dashboard-distribution">
                                <div className="dashboard-distribution-text">
                                We're here to help you get the most out of social media!
                                If you have existing pages, link them below and we'll
                                handle the rest! And if you don't, we'll help you get
                                started. Creation and distribution is one click away!
                                </div>
                                <div className="dashboard-distribution-socials">
                                    <input placeholder={"facebook.com/your-facebook-page"}/>
                                    <input placeholder={"instagram.com/your-instagram-page"}/>
                                    <input placeholder={"tiktok.com/@your-tiktok-page"}/>
                                </div>
                                <div className="send-socials-button">
                                    <AdButton
                                        text="DISTRIBUTE"
                                        background="linear-gradient(224.83deg, #E1A9F5 -83.1%, #D093E6 16.18%, #F36F56 138.18%)"
                                        fontSize="15px"
                                        fontWeight="600"
                                        width="50%"
                                        padding="10px 0px"
                                    >

                                    </AdButton>
                                </div>

                            </div>
                            <hr style={{border: "0.5px solid #bbb", width: "100%"}}/>
                                <div className="your-content-text">Posted Content 📷</div>
                                <div
                                    className="analytics"
                                    style={{padding: "15px 40px 40px 40px"}}
                                >
                                    No content posted yet - come back later!
                                </div>
                            </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function AdButton(props) {
    const {
        text,
        background,
        discount,
        fontSize,
        fontWeight,
        width,
        padding,
        onClick,
    } = props;
    let divStyle = {
        background: background,
        width: width,
        fontSize: fontSize,
        fontWeight: fontWeight,
        padding: padding,
    };
    let containerStyle = {
        padding: padding,
    };
    return (
        <button class="admagic-button" style={divStyle} onClick={onClick}>
            <div className="container" style={containerStyle}>
                {text}
                {discount}
            </div>
        </button>
    );
}

function UserButton(props) {
    const {icon, text, type, radius, padding} = props;
    let display;
    let cursor;
    let radiusDef;
    let paddingDef;
    if (type == undefined) {
        display = <button> {text} </button>;
        cursor = "pointer";
    } else {
        display = type;
        cursor = "";
    }
    if (radius != undefined) {
        radiusDef = radius;
    }
    if (padding != undefined) {
        paddingDef = padding;
    }
    const divStyle = {
        padding: paddingDef,
    };
    return (
        <label
            class="dashboard-user-icons"
            style={{cursor: {cursor}, borderRadius: radiusDef}}
        >
            <div
                class="background-button-solid"
                style={{cursor: {cursor}, borderRadius: radiusDef}}
            >
                <div class="container" style={divStyle}>
                    {icon}
                    {display}
                </div>
            </div>
        </label>
    );
}

const FeedbackForm = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState("");
    const {analysisFeedback} = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, feedback);
        const companyEmail = "contact@getadmagic.com"
        window.location.href = `mailto:${companyEmail}?subject=Feedback&body=${name}: ${feedback}`;
    }

    if (analysisFeedback) {
        return (
            <div className="dashboard-box" style={{paddingBottom: "10px"}}>
                <div className="your-content-text">Feedbacks & Concerns</div>
                <hr style={{border: "1px solid #bbb"}}/>
                <div
                    style={{
                        fontSize: "14px",
                        padding: "14px 33px",
                        lineHeight: "21px",
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 0,
                        flexShrink: 0, // Prevent the div from shrinking
                        width: "350px", // Set a specific width for the div
                        whiteSpace: "normal", // Allow text to wrap normally
                    }}
                >
                    <b style={{fontWeight: 700}}>
                        Any features you would like added? Questions about AdMagic?{" "}
                    </b>{" "}
                    <br/>
                    Feel free to fill out the form below to speak with us! A member
                    of our team will be in contact ASAP with the email you provide.
                </div>

                <div className="feedback-horizontal-div">
                    <div className="feedback-vertical-div" style={{flexGrow: 1}}>
                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}/>
                        <input type="text" placeholder="Email" value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                        <div
                            className="feedback-vertical-div"
                            style={{width: "100%", height: "100%"}}
                        >
                            <textarea type="text" placeholder="Feedback" value={feedback}
                                      onChange={(e) => setFeedback(e.target.value)}/>
                        </div>
                        <AdButton
                            text="SUBMIT"
                            background="linear-gradient(180deg, #725F4D -43.94%, #3D3632 112.03%"
                            fontSize="14px"
                            fontWeight="600"
                            width="100%"
                            padding="7px auto"
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div
                className="dashboard-box"
                style={{width: "100%", paddingBottom: "10px"}}
            >
                <div className="your-content-text">Feedbacks & Concerns</div>
                <hr style={{border: "1px solid #bbb", width: "100%"}}/>
                <div
                    style={{
                        fontSize: "14px",
                        padding: "14px 33px",
                        lineHeight: "21px",
                    }}
                >
                    <b style={{fontWeight: 700}}>
                        Any features you would like added? Questions about AdMagic?{" "}
                    </b>{" "}
                    Feel free to fill out the form below to speak with us! A member
                    of our team will be in contact ASAP with the email you provide.
                </div>
                <div className="feedback-horizontal-div">
                    <div className="feedback-vertical-div" style={{flexGrow: 1}}>
                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}/>
                        <input type="text" placeholder="Email" value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                        <AdButton
                            text="SUBMIT"
                            background="linear-gradient(180deg, #725F4D -43.94%, #3D3632 112.03%"
                            fontSize="14px"
                            fontWeight="600"
                            width="100%"
                            padding="7px auto"
                            onClick={handleSubmit}
                        />
                    </div>
                    <div
                        className="feedback-vertical-div"
                        style={{flexGrow: 3, height: "100%"}}
                    >
                        <textarea
                            type="text"
                            placeholder="Feedback"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}

                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard;
