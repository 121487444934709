import { React, useState, useEffect } from "react";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import Footer from "./Footer";
import "./Home.css";
import axios from "axios";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

function Home() {
  // pull data from https://5ln275k4d2.execute-api.us-east-1.amazonaws.com/business-info
  // and store it in a variable called "data"
  // use the useEffect hook to fetch the data from the API
  // and store it in the variable "data"
  const [data, setData] = useState([]);
  const [user, setUser] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        axios
          .get(
            "https://5ln275k4d2.execute-api.us-east-1.amazonaws.com/business-info"
          )
          .then((response) => {
            setData(response.data);
          });
      });
  }, []);
  console.log(user);

  return (
    <div className="container-center-horizontal home">
      {user && user.attributes.email_verified ? (
        <Link to="/dashboard">
          <button id="my-account-button">
            <img src={"img/user.svg"} alt="User" height="20em" />
            <label> {user.attributes.name} </label>
          </button>
        </Link>
      ) : (
        <Link to="/login">
          <button id="my-account-button">
            <img src={"img/user.svg"} alt="User" height="20em" />
            <label> Login </label>
          </button>
        </Link>
      )}

      <div className="home-neil">
        <div className="overlap-group10">
          <FirstSection
            className="frame-1-1"
            data={data}
            user={user && user.attributes.email_verified}
          />
          <SecondSection />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
