import { React, useState, useEffect } from "react";
import "./ShowAds.css";
import { useHistory, useLocation } from "react-router-dom";
import { API, Auth } from "aws-amplify";

function ShowAds() {
  const [selectedVideo, setSelectedVideo] = useState(-1);
  const history = useHistory();
  console.log(selectedVideo);

  const location = useLocation();
  const currentGeneratedVids = location.state?.currentGeneratedData;
  const createVideos = () => {
    let videoPreviews = [];
    for (let i = 0; i < currentGeneratedVids.length; i++) {
      videoPreviews.push(
        <label
          className={i === selectedVideo ? "ad-video-selected" : "ad-video"}
          onFocus={() => handleVideoClick(i)}
          onBlur={handleVideoBlur}
        >
          <video
            id={i}
            className={
              i === selectedVideo ? "video ad-video-selected" : "video ad-video"
            }
            width="250px"
            height="444px"
            controls
          >
            <source src={currentGeneratedVids[i]} type="video/mp4" />
          </video>
        </label>
      );
    }
    return videoPreviews;
  };

  const buttonClick = () => {
    console.log("buttonClick called");
    const vidParts = currentGeneratedVids[selectedVideo].split("/");
    const selectedVideoName = vidParts.slice(-2).join("/");

    API.post("user-data", "/select_video", {
      body: {
        video: selectedVideoName,
      },
    })
      .then((response) => {
        console.log(response);
        history.push({
          pathname: "/show-ad",
          search: `?video=${response.videoFile}`,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleVideoClick = (ix: number) => {
    // Pause all other videos
    for (let i = 0; i < currentGeneratedVids.length; i++) {
      if (i !== ix) {
        const otherVideo = document.getElementById(`${i}`) as HTMLVideoElement;
        otherVideo.pause();
      }
    }
    setSelectedVideo(ix);
  };

  const handleVideoBlur = () => {
    const focusedElement = document.activeElement;
    const isVideoFocused = currentGeneratedVids.some((_, i) =>
      focusedElement.matches(`.video.ad-video[id="${i}"]`)
    );
  };

  return (
    <div className="container-center-horizontal show-ads">
      <div className="horizontal-flex">
        <div className="vertical-flex-separator">
          <div class="vertical-flex">
            <div class="header-text">Take your pick...</div>
            <div class="subheading-text">
              We’ve cooked up some ads for you, of which you can choose one to
              download and share. Choose the one you like most to take home!
            </div>
            <div class="video-container">{createVideos()}</div>
            <button disabled={selectedVideo === -1} onClick={buttonClick}>
              NEXT
            </button>
          </div>
          <div className="footer-text">
            Based on what you like, we’ll further tailor your ads going forward
            🤝
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowAds;
