import { React, useState, useEffect } from "react";
import "./FormInput.css";
import { AutoComplete } from "antd";

const FormInput = ({
  setCity,
  setBusinessName,
  options,
  city,
  businessName,
  cityOptions,
  businessNameOptions,
  validCity,
  validBusiness,
}) => {
  function filterOptions(inputValue, option) {
    const substringValid =
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    const validLength = inputValue.length >= 1;
    return substringValid && validLength;
  }
  return (
    <>
      <div id="home-form" className="form-div">
        <div className="form">
          <AutoComplete
            className={"city-form"}
            autoComplete="off"
            placeholder="Enter your city."
            options={city.length >= 1 ? cityOptions : []}
            onChange={(data) => setCity(data)}
            bordered={false}
            value={city}
            notFoundContent={city.length >= 1 ? "City not found." : null}
            filterOption={filterOptions}
          />
        </div>
        {validCity && city !== "" && (
          <div className="form">
            <AutoComplete
              id="business"
              className={"city-form"}
              autoComplete="off"
              notFoundContent={
                businessName.length >= 1 ? "Business not found." : null
              }
              placeholder="Enter your business name."
              onChange={(data) => setBusinessName(data)}
              bordered={false}
              value={businessName}
              disabled={!validCity}
              options={businessName.length >= 1 ? businessNameOptions : []}
              filterOption={filterOptions}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FormInput;
