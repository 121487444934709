.invalidPageContainer {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #fff8ea;
  font-family: var(--font-family-montserrat);
  padding: 50px 40px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invalidPageContainer h1 {
  font-family: inherit;
  font-size: 4rem;
  font-weight: 700;
  box-sizing: border-box;
  padding-bottom: 25px;
}

.invalidPageContainer p {
  font-size: 1.2rem;
  font-weight: 400;
  padding-bottom: 30px;
}

.invalidPageContainer button {
  align-items: center;
  background: linear-gradient(180deg, #f78768 0%, #cf6547 152.17%);
  border-radius: 28px;
  box-shadow: 0px 2px 4px #00000040;
  cursor: pointer;
  height: 57px;
  text-align: center;
  outline: none;
  border: none;
  padding: 0px 25px;
  margin: 5px 0px;

  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: inherit;
  color: white;
  letter-spacing: 0.4px;
}
